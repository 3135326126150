import React from "react"

import Layout from "components/Layout"
import PageHeader from "components/PageHeader"
import { Main } from "page_components/privacy_policy"

const PrivacyPolicy = () => (
  <Layout seo={{ title: "Polityka prywatności" }}>
    <PageHeader title="Polityka prywatności" />
    <Main />
  </Layout>
)

export default PrivacyPolicy
