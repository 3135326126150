import "./styles.scss"

import React from "react"

const Main = () => {
  return (
    <section className="policy-privacy-main">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-md-10">
            <h4>1. Administrator danych</h4>
            <p>
              WS KRAK Sp. z o.o. z siedzibą w Krakowie, ul. Kronikarza Galla
              5/1, 30-053 Kraków, zarejestrowaną w Sądzie Rejonowym dla
              Krakowa-Śródmieścia w Krakowie, XI Wydział Gospodarczy Krajowego
              Rejestru Sądowego pod nr KRS: 0000940427, NIP: 6772472423, REGON:
              520735118, (dalej: DES).
            </p>

            <h4>2. Dane kontaktowe</h4>
            <p>
              Z DES można się skontaktować poprzez adres email:
              office@des.krakow.pl, pod numerem telefonu 12 637-92-92 lub
              pisemnie: WS KRAK Sp. z o.o. z siedzibą ul. Kronikarza Galla 5/1,
              30-053 Kraków. Inspektorem danych osobowych jest Wojciech Skręt,
              tel. 668 492 948 wojtek@kw51.pl.
            </p>

            <h4>3. Cele przetwarzania oraz podstawa prawna przetwarzania</h4>
            <ul>
              <li>
                Pani/Pana dane będą przetwarzane w celu udzielenia dodatkowych
                informacji handlowych z zakresu oferty DES. Podanie danych jest
                dobrowolne i umożliwia uzyskanie informacji handlowej – podstawą
                prawną przetwarzania danych jest Pani/Pana zgoda pod warunkiem,
                że ta zgoda została przez Panią/Pana udzielona, w razie
                nieudzielenia zgody dane osobowe nie są przetwarzane w tym celu.
              </li>
              <li>
                Pani/Pana dane będą przetwarzane w celu marketingu
                bezpośredniego dotyczącego produktów i usług własnych DES –
                podstawą prawną przetwarzania danych jest Pani/Pana zgoda pod
                warunkiem, że ta zgoda została przez Panią/Pana udzielona, w
                razie nieudzielenia zgody dane osobowe nie są przetwarzane w tym
                celu.
              </li>
              <li>
                Pani/Pana dane będą przetwarzane w celu realizacji usług takich
                jak np. udział w webinarze – podstawą prawną przetwarzania
                danych jest Pani/Pana zgoda pod warunkiem, że ta zgoda została
                przez Panią/Pana udzielona, w razie nieudzielenia zgody dane
                osobowe nie są przetwarzane w tym celu.
              </li>
              <li>
                Pani/Pana dane będą przetwarzane w celu przeprowadzenia procesu
                rekrutacji na dane stanowisko – podstawą prawną przetwarzania
                danych jest Pani/Pana zgoda udzielona przy wypełnianiu
                formularza zgłoszeniowego (aplikacja na dane stanowisko).
              </li>
            </ul>

            <h4>4. Okres, przez który dane będą przechowywane</h4>
            <p>
              Dane będą przetwarzane przez 2 lata lub do momentu cofnięcia przez
              Panią/Pana zgody na przetwarzanie danych osobowych w celach
              marketingowych.
            </p>

            <h4>5. Odbiorcy danych</h4>
            <p>
              Pani/Pana dane mogą być przekazywane podmiotom przetwarzającym
              dane osobowe na zlecenie DES w szczególności dostawcom usług IT i
              biurom rachunkowym – przy czym takie podmioty będą przetwarzać
              dane na podstawie umowy z DES i wyłącznie zgodnie z poleceniami
              DES.
            </p>

            <h4>6. Przekazywanie danych poza Europejski Obszar Gospodarczy</h4>
            <p>
              Pani/Pana dane nie będą przekazywane do odbiorców znajdujących się
              w państwach poza Europejskim Obszarem Gospodarczym.
            </p>

            <h4>7. Prawa osoby, której dane dotyczą</h4>
            <p>Przysługuje Pani/Panu prawo:</p>
            <ul>
              <li>dostępu do Pani/Pana danych</li>
              <li>żądania ich sprostowania</li>
              <li>żądania ich usunięcia</li>
              <li>żądania ograniczenia przetwarzania,</li>
              <li>wycofania zgody,</li>
              <li>
                prawo do przenoszenia danych osobowych, tj. do otrzymania od DES
                Pani/Pana danych osobowych w pliku o powszechnie używanym
                formacie,
              </li>
              <li>
                wniesienia skargi do organu nadzorczego zajmującego się ochroną
                danych osobowych.
              </li>
            </ul>
            <p>
              W celu realizacji powyżej wymienionych praw należy skontaktować
              się z DES. Można to zrobić np. przesyłając wniosek na adres
              siedziby DES lub e-mailowo na adres office@des.krakow.pl
            </p>

            <h4>8. Dobrowolność podania danych</h4>
            <p>
              Podanie danych osobowych w celach marketingowych jest dobrowolne.
              Podanie danych osobowych w celu rozpatrywania ewentualnej
              reklamacji jest dobrowolne, jednak ich niepodanie uniemożliwi
              przyjęcie i rozpatrzenie reklamacji.
            </p>

            <h4>9. Podjęte środki ochrony danych</h4>
            <ul>
              <li>
                Ochrona danych odbywa się zgodnie z wymogami powszechnie
                obowiązujących przepisów prawa, a ich przechowywanie ma miejsce
                na zabezpieczonych serwerach.
              </li>
              <li>
                Szanujemy prawo do prywatności i dbamy o bezpieczeństwo danych.
                W tym celu używany jest m.in. bezpieczny protokół szyfrowania
                komunikacji (SSL).
              </li>
              <li>
                Wdrożyliśmy szyfrowanie danych oraz mamy wprowadzoną kontrolę
                dostępu dzięki czemu minimalizujemy skutki ewentualnego
                naruszenia bezpieczeństwa danych.
              </li>
            </ul>

            <h4>10. Pliki cookies</h4>
            <p>
              Witryna mazowiecka115.pl używa cookies. Są to niewielkie pliki
              tekstowe wysyłane przez serwer www i przechowywane przez
              oprogramowanie komputera przeglądarki. Kiedy przeglądarka ponownie
              połączy się ze stroną, witryna rozpoznaje rodzaj urządzenia, z
              którego łączy się użytkownik. Parametry pozwalają na odczytanie
              informacji w nich zawartych jedynie serwerowi, który je utworzył.
              Cookies ułatwiają więc korzystanie z wcześniej odwiedzonych
              witryn. Gromadzone informacje dotyczą adresu IP, typu
              wykorzystywanej przeglądarki, języka, rodzaju systemu
              operacyjnego, dostawcy usług internetowych, informacji o czasie i
              dacie, lokalizacji oraz informacji przesyłanych do witryny za
              pośrednictwem formularza kontaktowego.
            </p>
            <p>
              Zebrane dane służą do monitorowania i sprawdzenia, w jaki sposób
              użytkownicy korzystają z naszych witryn, aby usprawniać
              funkcjonowanie serwisu zapewniając bardziej efektywną i
              bezproblemową nawigację. Monitorowania informacji o użytkownikach
              dokonujemy korzystając z narzędzia Google Analitics, które
              rejestruje zachowanie użytkownika na stronie. Cookies identyfikuje
              użytkownika, co pozwala na dopasowanie treści witryny, z której
              korzysta, do jego potrzeb. Zapamiętując jego preferencje,
              umożliwia odpowiednie dopasowanie skierowanych do niego reklam.
              Stosujemy pliki cookies, aby zagwarantować najwyższy standard
              wygody naszego serwisu, a zebrane dane są wykorzystywane jedynie
              wewnątrz firmy DES w celu optymalizacji działań.
            </p>
            <p>Na naszej witrynie wykorzystujemy następujące pliki cookies:</p>
            <ul>
              <li>
                „niezbędne” pliki cookies, umożliwiające korzystanie z usług
                dostępnych w ramach serwisu, np. uwierzytelniające pliki cookies
                wykorzystywane do usług wymagających uwierzytelniania w ramach
                serwisu;
              </li>
              <li>
                pliki cookies służące do zapewnienia bezpieczeństwa, np.
                wykorzystywane do wykrywania nadużyć w zakresie uwierzytelniania
                w ramach serwisu;
              </li>
              <li>
                „wydajnościowe” pliki cookies, umożliwiające zbieranie
                informacji o sposobie korzystania ze stron internetowych
                serwisu;
              </li>
              <li>
                „reklamowe” pliki cookies, umożliwiające dostarczanie
                użytkownikom treści reklamowych bardziej dostosowanych do ich
                zainteresowań.
              </li>
            </ul>
            <p>
              Użytkownik w każdej chwili ma możliwość wyłączenia lub
              przywrócenia opcji gromadzenia cookies poprzez zmianę ustawień w
              przeglądarce internetowej. Instrukcja zarządzania plikami cookies
              jest dostępna na stronie:
              http://www.allaboutcookies.org/manage-cookies
            </p>
            <p>
              Dodatkowe dane osobowe, jak adres e-mail, zbierane są jedynie w
              miejscach, w których użytkownik wypełniając formularz wyraźnie
              wyraził na to zgodę. Powyższe dane zachowujemy i wykorzystujemy
              tylko do potrzeb niezbędnych do wykonania danej funkcji.
            </p>
            <p>
              Więcej o ciasteczkach (cookies) w naszej Polityce Plików Cookies.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Main
